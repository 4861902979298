.serpresult {
    max-width: 600px;
    min-width: 600px;
    margin-bottom: 2em;
    margin-left: 1em;

    padding: 1em;
    border: 1px #dedede solid;
}

.serpresult .gen {
    font-family: serif;
}

.resultcontainer {
    display: flex;
    flex-direction: row;
}

.resultstats {
    display: flex;
    flex-direction: column;
    max-width: 50px;
    font-size: 8pt;
}

.resultstats div {
    text-align: center;
    border: 1px #dedede solid;
    padding: 0.1em;
    margin: 0 0 -1px -1px;
}

.t, .u {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.t a {
    font-size: 14pt;
    color: blue;
    text-decoration: none;;
}

.u a {
    font-size: 12pt;
    color: green;
    text-decoration: none;;
}
.s {
    font-size: 12pt;
    color: #101010;
    text-align: justify;
}

.t a p, .s p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.t a p strong, .s p strong {
    font-weight: 500
}

.stats {
    font-size: 8pt;
    margin: 1em;

}
