main {
    margin-left: 150px;
    margin-right: 150px;
    width: 648px;
}

aside {
    width: 432px;
}

div.fb_harder {
    background: #f0a4006e;
    margin: 1em -1.5em 1.5em -1.5em;
    padding:  1em;
    border-radius: .75em;
    color:  black;
    border: 1px #e1e1e1 solid;
    font: 14px/normal 'Roboto',Helvetica,Sans-Serif;
    line-height: 22px;
}

.waiting {
    font-size: 60px;
    animation-name: rotating;
    position: absolute;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes rotating {
    from { transform: rotate(0); }    
    to { transform: rotate(360deg);}
}

.richrswrapper {
    box-shadow: 0 2px 3px rgba(0,0,0,.1),0 -1px 1px rgba(0,0,0,.1);
    padding: 9px 19px 4px 19px;
    margin: 0px -20px 24px -20px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ddd;
    font: 14px/normal 'Roboto',Helvetica,Sans-Serif;
    line-height: 22px;
}

div.rightrailmg {
    padding-bottom: 1.5em;
    color: black;
    background-color: aliceblue;
}

div.rightrailmg a, div.rightrailmg a:visited {
    color: purple;
}