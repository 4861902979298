.App {
  display: grid;
  grid-template-columns: 1fr 1fr 20%;
  grid-gap: 16px;
}

.header {
  grid-column-start: 1;
  grid-column-end: -1;;
  background: linear-gradient(180deg, #0000ff2b, transparent);
  padding: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

#searchbox {
  flex-grow: 1;
}

.scores {
  grid-column-end: span 2;
  text-align: center;
}


.results {
  /*
  grid-column-end: span 3;
  grid-row-end: span 3;
  */
}

.footer {
  grid-column-end: span 3;
}

.meta {
  font-size: 8pt;
}